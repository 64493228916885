import styled from 'styled-components'

import { IconButton } from '@tinacms/styles'

export const DropArea = styled.div`
  border-radius: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  outline: none;
  cursor: pointer;
  margin-top: var(--tina-padding-small);
  margin-bottom: var(--tina-padding-small);
`

export const FilePlaceholder = styled.div`
  text-align: center;
  border-radius: 16px;
  background-color: var(--tina-color-grey-2);
  color: var(--tina-color-grey-4);
  line-height: 1.35;
  font-size: 18px;
  font-weight: 500;
  transition: all 85ms ease-out;
  padding: 16px;
  width: 100%;
  min-height: 200px;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.6;
  }
`

export const FileUploadPreview = styled.div`
  max-width: 100%;
  min-height: 200px;
  border-radius: 16px;
  transition: opacity var(--tina-timing-short) ease-out;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  ${({ value }) => `background-image: url(${value});`}
  svg {
    width: 64px !important;
    height: 64px !important;
    color: var(--tina-color-grey-0);
  }
`

export const DeleteButton = styled(IconButton)`
  top: 8px;
  right: 8px;
  position: absolute;
  &:not(:hover) {
    fill: var(--tina-color-grey-0);
    background-color: transparent;
    border-color: transparent;
  }
`

export const FilePreviewContainer = styled.div`
  background-color: var(--tina-color-grey-4);
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  background-color: var(--tina-color-grey-8);
  &:hover {
    ${FileUploadPreview} {
      opacity: 0.6;
    }
  }
`

export const FileUploadField = styled.div`
  margin-bottom: var(--tina-padding-big);
  width: 100%;
`
