import config from 'config'
import { nameToSlug, isServerSide } from '../utils'

const Parse = isServerSide() ? require('parse/node') : require('parse')

/**
 * Uploads file to Parse Server.
 * @description Turns File object into downloadable URL.
 */
const upload = async ({ folder, file }) => {
  // Gets unique filename (e.g.: cms-1823812831823-filenamejpg)
  const filename = `${folder}-${Date.now()}-${nameToSlug(file.name)}`

  // Gets Parse Server URL info
  const { serverURL = '' } = config.parseConfig
  const localServerURL = 'http://localhost:9000/parse'
  Parse.serverURL = serverURL

  // Creates Parse File instance
  const parseFile = new Parse.File(filename, file)
  // Saves Parse File on server
  await parseFile.save()

  // Gets download URL
  const downloadUrl = parseFile.url()

  // Fixes URL host to use actual Server URL
  const url = downloadUrl.replace(localServerURL, serverURL)

  // Returns fixed URL
  return url
}

export default { upload }
