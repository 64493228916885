import { getDefaultPost, toSlug } from './helpers'

export const BackgroundField = {
  label: 'Plano de fundo',
  name: 'background',
  component: 'file',
}

export const FileField = {
  label: 'Arquivo',
  name: 'file',
  component: 'file',
}

export const ImageField = {
  label: 'Imagem principal',
  name: 'image',
  component: 'group',
  fields: [
    {
      label: 'Imagem',
      name: 'src',
      component: 'file',
    },
    {
      label: 'Texto alternativo',
      name: 'alt',
      component: 'textarea',
    },
  ],
}

export const ParagraphField = {
  label: 'Parágrafos',
  name: 'paragraphs',
  component: 'textarea',
}

export const TitleField = {
  label: 'Título',
  name: 'title',
  component: 'textarea',
}

export const TitleTopField = {
  label: 'Tópico',
  name: 'titleTop',
  component: 'text',
}

export const TitleTopDescriptionField = {
  label: 'Subtítulo do tópico',
  name: 'titleTopDescription',
  component: 'text',
}

export const VideoLinkField = {
  label: 'Vídeo',
  name: 'video',
  component: 'text',
}

export const DescriptionField = {
  label: 'Descrição',
  name: 'description',
  component: 'textarea',
}

export const SubtitleField = {
  label: 'Subtítulo',
  name: 'subtitle',
  component: 'textarea',
}

export const LinkTextField = {
  label: 'Texto do link',
  name: 'linkText',
  component: 'text',
}

export const LinkField = {
  label: 'Endereço do Link',
  name: 'href',
  component: 'text',
}

export const CardsField = {
  label: 'Cards',
  name: 'cards',
  component: 'group-list',
  itemProps: (item) => ({
    key: item.id,
    label: item.title,
  }),
}
export const TextGroupField = {
  label: 'Título e descrição',
  name: 'label',
  component: 'group',
  itemProps: (item) => ({
    key: item.id,
    label: item.title,
  }),
}

export const CardCoverField = {
  label: 'Imagem do card',
  name: 'cover',
  component: 'file',
}

export const CardTitleField = { ...TitleField, label: 'Título do card' }

export const CardContentField = {
  label: 'Conteúdo do card',
  name: 'content',
  component: 'textarea',
}

export const CardLinkField = { ...LinkField, label: 'Link do card' }

export const PostsField = {
  label: 'Lista de posts',
  name: 'items',
  component: 'group-list',
  defaultItem: getDefaultPost,
  itemProps: (item) => ({
    key: item.id,
    label: item.href,
  }),
  fields: [
    { ...LinkField, label: 'Link do post', format: (value = '') => toSlug(value) },
    {
      ...TitleField,
      label: 'Título do post',
      component: 'text',
    },
  ],
}

export const PostsWithContentField = {
  ...PostsField,
  fields: [
    {
      label: 'Conteúdo do post',
      name: 'content',
      component: 'blog-post-anchor',
    },
  ].concat(PostsField.fields),
}

export const ThirdPagesField = {
  label: 'Lista de terceiras páginas de SS',
  name: 'items',
  component: 'group-list',
  defaultItem: getDefaultPost,
  itemProps: (item) => ({
    key: item.id,
    label: item.href,
  }),
  fields: [
    { ...LinkField, label: 'Link da página' },
    { ...BackgroundField, label: 'Imagem do Header' },
    {
      ...TitleField,
      label: 'Título do Header',
      component: 'text',
    },
  ],
}

export const ThirdPagesWithContentField = {
  ...ThirdPagesField,
  fields: [
    {
      label: 'Conteúdo da página',
      name: 'content',
      component: 'third-page-anchor',
    },
  ]
    .concat(ThirdPagesField.fields)
    .concat([
      {
        label: 'Texto da galeria de imagens',
        name: 'textGalery',
        component: 'text',
      },
      {
        ...ImageField,
        label: 'Primeira imagem do mosaico',
        name: 'imageGaleryOne',
        enable: 'false',
      },
      {
        ...ImageField,
        label: 'Segunda imagem do mosaico',
        name: 'imageGaleryTwo',
      },
      {
        ...ImageField,
        label: 'Terceira imagem do mosaico',
        name: 'imageGaleryThree',
      },
    ]),
}

export const NumbersField = {
  label: 'Números da seção',
  name: 'numbers',
  component: 'group-list',
  itemProps: (item) => ({
    key: item.id,
    label: item.subtitle,
  }),
  fields: [
    {
      label: 'Valor',
      name: 'number',
      component: 'text',
    },
    {
      label: 'Unidade do valor',
      name: 'unit',
      component: 'text',
    },
    {
      label: 'Legenda do valor',
      name: 'subtitle',
      component: 'text',
    },
  ],
}

export const VideoField = {
  label: 'Video',
  name: 'video',
  component: 'group',
  fields: [
    {
      label: 'Link do vídeo (ex.: https://www.youtube.com/embed/...)',
      name: 'src',
      component: 'text',
    },
    {
      label: 'Título do vídeo (opcional)',
      name: 'title',
      component: 'text',
    },
  ],
}

export const TopTitleField = {
  label: 'Tópico',
  name: 'topTitle',
  component: 'text',
}

export const SubHeading = {
  label: 'Cabeçalho',
  name: 'subHeading',
  component: 'text',
}

export const ParagraphsField = {
  label: 'Parágrafos',
  name: 'paragraphList',
  component: 'group-list',
  itemProps: (item) => ({
    key: item.id,
    label: item.content,
  }),
  fields: [
    {
      label: 'Conteúdo do parágrafo',
      name: 'content',
      component: 'textarea',
    },
  ],
}

export const TimelineField = {
  label: 'Linha do tempo',
  name: 'timelineData',
  component: 'group',
  fields: [
    {
      label: 'Título da linha do tempo',
      name: 'timelineTitle',
      component: 'text',
    },
    {
      label: 'Descrição',
      name: 'timelineDescription',
      component: 'text',
    },
    {
      label: 'Anos na linha do tempo',
      name: 'timelineYears',
      component: 'group-list',
      itemProps: (item) => ({
        key: item.id,
        label: item.year,
      }),
      fields: [
        {
          label: 'Ano',
          name: 'year',
          component: 'text',
        },
        {
          label: 'Pedidos Por Mês',
          name: 'ordersPerMonth',
          component: 'text',
        },
        {
          label: 'Informação ao topo',
          name: 'topInfo',
          component: 'html',
        },
        {
          label: 'Informação abaixo',
          name: 'bottomInfo',
          component: 'html',
        },
      ],
    },
  ],
}

export const CardsWithLogoField = {
  label: 'Cards',
  name: 'cards',
  component: 'group-list',
  itemProps: (item) => ({
    key: item.id,
    label: item.content,
  }),
  fields: [
    {
      label: 'Logo do produto',
      name: 'logo',
      component: 'file',
    },
    {
      label: 'Imagem do card',
      name: 'cover',
      component: 'file',
    },
    {
      label: 'Conteúdo do card',
      name: 'content',
      component: 'textarea',
    },
  ],
}

export const ButtonField = {
  label: 'Botão',
  name: 'button',
  component: 'group',
  fields: [
    {
      label: 'Texto do botão',
      name: 'text',
      component: 'text',
    },
    {
      label: 'Link da página',
      name: 'link',
      component: 'text',
    },
  ],
}

export const SelectField = {
  component: 'select',
  options: [],
}

export const LinkType = {
  label: 'Tipo de link',
  name: 'linkType',
  component: 'select',
  options: [
    { label: 'Link Interno', value: 'internal-link' },
    { label: 'Link Externo', value: 'external-link' },
  ],
}

export const NumberField = {
  label: 'Número',
  name: 'number',
  component: 'number',
}
