import React from 'react'
import PropTypes from 'prop-types'
import { TinaCMS, TinaProvider } from 'tinacms'

import { CmsSpeedDial } from 'plugins/content/components'
import { isLoggedIn } from 'plugins/content/services/auth'

import CmsConfig from './CmsConfig'

const CmsProvider = ({ element }) => {
  const cms = new TinaCMS({
    enabled: isLoggedIn(),
    sidebar: {
      position: 'displace',
    },
  })

  return (
    <TinaProvider cms={cms}>
      <CmsConfig />
      {element}
      {isLoggedIn() && <CmsSpeedDial />}
    </TinaProvider>
  )
}

CmsProvider.propTypes = {
  element: PropTypes.node.isRequired,
}

export default CmsProvider
