import { useEffect, useState } from 'react'

const imageExtensions = ['png', 'jpg', 'jpeg']

const isLocalImage = (src) =>
  process.env.NODE_ENV !== 'development' && typeof src === 'string' && src.indexOf('http') === -1

const srcToWebp = (src) => {
  // eslint-disable-next-line
  for (const index in imageExtensions) {
    const extension = imageExtensions[index]
    if (src.indexOf(extension) > -1) {
      return src.replace(`.${extension}`, '.webp')
    }
  }
  return src
}

export default function useCmsImage(source, type) {
  const webpSource = isLocalImage(source) ? srcToWebp(source) : source

  const [imageSharp, setImageSharp] = useState({ [type]: source })
  useEffect(() => {
    if (typeof source === 'string') {
      // In case the CMS is on editing mode
      const img = new Image()
      img.onload = () => {
        const image = { src: img.src, width: img.width, height: img.height }
        // Fetches the image in order to get its dimensions
        setImageSharp({ [type]: image })
      }

      img.src = webpSource
    }
  }, [webpSource, source, type])

  return [imageSharp]
}
