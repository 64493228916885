import slug from 'slug'
import { v4 as uuid } from 'uuid'

import deepmerge from 'deepmerge'

export const isEmpty = (obj = {}) => Object.keys(obj).length === 0

export const isServerSide = () => typeof window === 'undefined'

export const convertObjectValuesRecursive = (source, target, replacement) => {
  const result = { ...source }

  Object.keys(result).forEach((key) => {
    // eslint-disable-next-line
    if (result[key] == target) {
      result[key] = replacement
    } else if (typeof result[key] === 'object' && !Array.isArray(result[key])) {
      result[key] = convertObjectValuesRecursive(result[key], target, replacement)
    }
  })

  return result
}

const overwriteMerge = (_, sourceArray) => sourceArray

export const mergeContent = (objectA = {}, objectB = {}) => {
  const values = deepmerge(objectA, objectB, { arrayMerge: overwriteMerge })
  const result = convertObjectValuesRecursive(values, null, undefined)
  return result
}

export const mergeArrays = (arrayA = [], arrayB = [], options) => {
  const result = arrayA
    .filter((a) => !arrayB.find((b) => b[options.by] === a[options.by]))
    .concat(arrayB)

  return result
}

export const removeSpecialChars = (text) => text.replace(/\W|_/g, '-')

export const nameToSlug = (name) => name && slug(name.toLowerCase())

export const getTimestamp = () => new Date().toISOString()

export const getUuid = () => uuid()

export const parseObject = (data) => {
  try {
    if (typeof data !== 'string') return data
    const parsed = JSON.parse(data)
    return parsed
  } catch (e) {
    return data
  }
}

const isObject = (object) => typeof object === 'object'

export const transformValues = async (source = {}, transform = () => {}) => {
  // In case the property is an array
  if (Array.isArray(source)) {
    // Proceed with iteration of the indexes
    for (let i = 0; i < source.length; i += 1) {
      if (isObject(source[i])) {
        // eslint-disable-next-line
        source[i] = await transformValues(source[i], transform)
      }
    }
    return source
  }

  // In case the property is an object
  // Proceed with iteration of the keys
  Object.keys(source || {}).forEach(async (key) => {
    // eslint-disable-next-line
    source[key] = await transform(key, source[key])

    if (isObject(source[key] || {})) {
      // eslint-disable-next-line
      source[key] = await transformValues(source[key], transform)
    }
  })

  return source
}

// Removes trailing slash from pathname (e.g: /article/name/ -> /article/name)
export const stripTrailingSlash = (href) => (href.endsWith('/') ? href.slice(0, -1) : href)
