import React from 'react'

import './styles.scss'

const Loader = () => (
    <>
      <div id="loader" />
    </>
  )

export default Loader
