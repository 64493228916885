const specialCharacters = /[`~!@#$%^&*()_+{}[\]\\|,.?;':"]/g

// eslint-disable-next-line
export const toSlug = (value = '') => {
  const slugified = value.replace(specialCharacters, '').replace(/ /g, '-')
  return slugified.startsWith('/') ? slugified : `/${slugified}`
}

export const getDefaultPost = () => {
  const now = new Date()
  const isoDate = now.toISOString().split('T')[0]
  const hours = `${now.getHours()}`.padStart(2, '0')
  const minutes = `${now.getMinutes()}`.padStart(2, '0')
  return {
    href: `/${isoDate}-${hours}h${minutes}-novo-artigo`,
    title: 'Título do artigo com até 60 caracteres...',
    content: '<p>Conteúdo do artigo</p>',
  }
}
