import { isServerSide } from '../utils/index'

const Parse = isServerSide() ? require('parse/node') : require('parse')

const STORAGE_USER_KEY = 'IFOOD_STORAGE_USER_KEY'

const setLoggedInUser = (user) =>
  !isServerSide() && localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(user))

const getLoggedInUser = () => {
  const stringified = !isServerSide() && localStorage.getItem(STORAGE_USER_KEY)
  return stringified === null ? undefined : JSON.parse(stringified)
}

const removeLoggedInUser = () => !isServerSide() && localStorage.clear()

export const isLoggedIn = () => !!getLoggedInUser()

export const login = async ({ user, password }) => {
  const result = await Parse.User.logIn(user, password)
  setLoggedInUser(result.toJSON())
  return result
}

export const logout = async () => {
  const result = await Parse.User.logOut()
  removeLoggedInUser()
  return result
}
