import React from 'react'

import { MdExitToApp } from 'react-icons/md'

import { logout } from '../../services/auth'

// eslint-disable-next-line
export const defaultOptions = [
  {
    uid: 'floating-menu-01',
    icon: <MdExitToApp title="Sair" />,
    onClick: async () => {
      try {
        await logout()
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
      } finally {
        window.location.reload()
      }
    },
  },
]
