import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import NewTabIcon from 'assets/svg/new-tab.svg'

import { isServerSide, stripTrailingSlash } from '../../../utils'
import { FieldLabel } from '../styles'

import './styles.scss'

// eslint-disable-next-line
const CmsContentAnchorField = ({ field, form }) => {
  const { name = '' } = field
  const [isLoading, setIsLoading] = useState(false)

  const href = form?.getFieldState(name.replace('content', 'href'))?.value || ''

  // On user click
  const handleClick = useCallback(() => {
    try {
      setIsLoading(true)
      // In case there are form changes
      if (form.getState().dirty) {
        // --- Submits form
        form.submit()
      }

      // After submit, waits for 5 seconds, then
      const timeoutRef = setTimeout(() => {
        // --- Redirects article page
        window.open(href, '_blank')

        setIsLoading(false)

        // --- Clears timeout ref
        clearTimeout(timeoutRef)
      }, 5000)
    } catch (e) {
      setIsLoading(false)
    }
  }, [form, href])

  if (!isServerSide() && href === stripTrailingSlash(window.location.pathname)) return <></>

  return (
    <div className="cms-content-anchor-field">
      {field.label && <FieldLabel>{field.label}</FieldLabel>}
      <button type="button" onClick={() => handleClick()} disabled={isLoading}>
        <NewTabIcon />
        &nbsp;
        {!isLoading ? 'Editar conteúdo' : 'Redirecionando você para a página do post...'}
      </button>
    </div>
  )
}

CmsContentAnchorField.propTypes = {
  form: PropTypes.shape().isRequired,
  field: PropTypes.shape().isRequired,
}

export default CmsContentAnchorField
