import database from './database'
import { triggerReleasePipeline } from './azure'

let isBuildRunning = false

// eslint-disable-next-line
export const triggerCmsBuild = async () => {
  try {
    // --- Prevents multiple builds to run simultaneously
    if (isBuildRunning) return
    // --- Sets the building flag
    isBuildRunning = true
    // --- Retrieves application configurations from database
    const environment = (await database.read('Config'))[0]
    // --- Triggers release pipeline upon Azure
    await triggerReleasePipeline({ ...environment })
    // --- And it is a... success! ✅
  } catch (e) {
    // --- And it is a... failure! ❌
    // eslint-disable-next-line
    console.log(e)
  } finally {
    // --- Clears the builing flag
    isBuildRunning = false
  }
}
