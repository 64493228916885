const APP_STAGE = process.env.GATSBY_PUBLIC_APP_STAGE || ''

/**
 * Creates common headers for Azure HTTP requests
 * @description Includes settings such as auth tokens and content type
 */
const getHttpRequestsHeaders = ({ AZURE_TOKEN = '' }) => {
  // Initializes headers
  const headers = new Headers()
  // Appends Azure Token (username:pat) as base64
  // 🕵️ About Personal Access Tokens (PAT):
  // https://docs.microsoft.com/en-us/azure/devops/organizations/accounts/use-personal-access-tokens-to-authenticate?view=azure-devops&tabs=preview-page
  headers.append('Authorization', `Basic ${AZURE_TOKEN}`)
  // Uses JSON as expected response content
  headers.append('Content-Type', 'application/json')
  // Returns headers
  return headers
}

/**
 * Retrieves deployments list from Azure Devops
 * @description Retrieves depolyments list from Azure via REST API
 */
const fetchAllDeployments = async ({
  deploymentsUrl = '',
  releasePipelineId = '',
  requestHeaders = new Headers(),
}) => {
  // We must retrieve only the deployments for the application specific pipeline
  // e.g.: The web app deployments but not the API deployments, mobile app deployments, etc
  const deploymentsByReleasePipeline = deploymentsUrl.replace(
    // To do so, we replace the release pipeline placeholder on URL
    '{RELEASE_PIPELINE_ID}',
    // With the proper pipeline id
    releasePipelineId,
  )
  // Then we request the data from the deployments URL at Azure
  const deploymentsResponse = await fetch(deploymentsByReleasePipeline, {
    method: 'GET',
    headers: requestHeaders,
  })
  // Finally, we convert the response to JSON format
  const deployments = await deploymentsResponse.json()
  // And return the actual deployments list (without the request metadata)
  return deployments.value || []
}

/**
 * Turns full deployments list into an object with the stages
 * @description Which stage will contain its latest deployment
 */
const groupDeploymentsByStage = ({ deployments = [] }) => {
  // Reduces deployments list into an object with the stages as keys
  const stages = deployments.reduce((accumulator, deployment) => {
    // For every deployment, do
    // --- Retrieves deployment stage (dev, prod, etc)
    const { releaseEnvironment: stage = { name: '' } } = deployment
    // --- Checks if the application stage is the same as the current
    const isCurrentApplicationStage = stage.name.toLowerCase().indexOf(APP_STAGE.toLowerCase()) > -1
    // --- In case the stage is not added yet
    if (!accumulator[stage.name] && isCurrentApplicationStage) {
      // --- Adds it to the stages list using the latest deployment as value
      accumulator[stage.name] = deployment
    }
    // --- Returns the current stages list
    return accumulator
  }, {})
  // Returns formatted stages list
  return stages
}

/**
 * Formats release artifact into Rest API expected format
 * @description Sets the proper settings into the object and returns it
 */
const formatArtifact = (artifact) => {
  // Retrieves artificact definitions
  const { definitionReference } = artifact
  // eslint-disable-next-line
  artifact.instanceReference = {
    // Sets the artifact id
    id: definitionReference.version.id,
    // Sets the source branch as the artifact branch
    sourceBranch: definitionReference.branches.id,
  }
  return artifact
}

/**
 * Checks if the release already exists on list in order to prevent duplicates
 * @description Looks for matches based on the release id.
 */
const isDuplicateRelease = ({ releases, release = { id: '' } }) =>
  releases.find((currentRelease) => currentRelease.id === release.id)

/**
 * Retrieves releases list from provided stages
 * @description Retrieves unique releases list
 */
const stagesToReleases = ({ stages = {} }) => {
  // Initializes releases list
  const releases = []
  // For every stage, do
  // eslint-disable-next-line
  for (const stageId in stages) {
    // --- Retrieves stage data
    const stage = stages[stageId]
    // --- Retrieves latest release from stage
    const { release = { id: '' } } = stage
    // --- Checks if the release already exists on list in order to prevent duplicates
    if (
      !isDuplicateRelease({
        releases,
        release,
      })
    ) {
      // ---- In case it doesn't, adds it to the list
      releases.push(release)
    }
  }
  return releases
}

/**
 * Formats releases into API expected structure.
 * @description Formats each release based on the Azure Devops docs
 */
const formatReleases = ({ releases, releasePipelineId = '' }) => {
  // Maps releases into API expected format
  const formatted = releases.map((release) => {
    // 🕵️ In order to check the full options list, check:
    // https://docs.microsoft.com/en-us/rest/api/azure/devops/release/releases/create?view=azure-devops-rest-6.0
    return {
      definitionId: releasePipelineId,
      artifacts: release.artifacts.map(formatArtifact),
      manualEnvironments: null,
      isDraft: false, // --- Is this a dry run release?
    }
  })
  return formatted
}

/**
 * Triggers releases list on Azure Devops
 * @description Submits each Release to be deployed by Azure via REST API
 */
const triggerReleases = async ({
  releasesUrl = '',
  requestHeaders = new Headers(),
  releases = [],
}) => {
  for (let index = 0; index < releases.length; index += 1) {
    const release = releases[index]
    // 🕵️ In order to understand the Release creation via API:
    // https://docs.microsoft.com/en-us/rest/api/azure/devops/release/releases/create?view=azure-devops-rest-6.0
    // eslint-disable-next-line
    await fetch(releasesUrl, {
      // Creates a request with
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(release, null, 4), // --- Stringified release as the body
    })
  }
}

/**
 * Triggers release on Azure Devops
 * @description Retrieves latest release for the provided stage
 * then deploys a new release with the same source code (branch/commit) + content updates
 */
// eslint-disable-next-line
export const triggerReleasePipeline = async ({
  AZURE_TOKEN = '',
  AZURE_DEPLOYMENTS_URL = '',
  AZURE_RELEASE_PIPELINE_ID = '',
  AZURE_RELEASE_URL = '',
}) => {
  // Initializes HTTP request common headers
  const headers = getHttpRequestsHeaders({
    AZURE_TOKEN, // --- With Azure authentication token
  })
  // Retrieves all pipeline deployments from Azure
  const deployments = await fetchAllDeployments({
    deploymentsUrl: AZURE_DEPLOYMENTS_URL,
    releasePipelineId: AZURE_RELEASE_PIPELINE_ID,
    requestHeaders: headers, // --- With common http headers
  })
  // Gets latest deployment for each stage (dev, prod, etc.)
  const stages = groupDeploymentsByStage({
    deployments, // --- Based on deployments list
  })
  // Gets latest releases list for each stage
  const releases = stagesToReleases({
    stages, // --- Based on stages list
  })
  const formattedReleases = formatReleases({
    releases,
    releasePipelineId: AZURE_RELEASE_PIPELINE_ID, // --- With release definition id
  })
  await triggerReleases({
    releasesUrl: AZURE_RELEASE_URL,
    requestHeaders: headers,
    releases: formattedReleases, // --- With common http headers
  })
}
