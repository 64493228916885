// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abrindo-a-cozinha-privacidade-cliente-versao-1-js": () => import("./../../../src/pages/abrindo-a-cozinha/privacidade-cliente-versao1.js" /* webpackChunkName: "component---src-pages-abrindo-a-cozinha-privacidade-cliente-versao-1-js" */),
  "component---src-pages-abrindo-a-cozinha-privacidade-cliente-versao-2-js": () => import("./../../../src/pages/abrindo-a-cozinha/privacidade-cliente-versao2.js" /* webpackChunkName: "component---src-pages-abrindo-a-cozinha-privacidade-cliente-versao-2-js" */),
  "component---src-pages-abrindo-a-cozinha-privacidade-cliente-versao-3-js": () => import("./../../../src/pages/abrindo-a-cozinha/privacidade-cliente-versao3.js" /* webpackChunkName: "component---src-pages-abrindo-a-cozinha-privacidade-cliente-versao-3-js" */),
  "component---src-pages-abrindo-a-cozinha-privacidade-cliente-versao-4-js": () => import("./../../../src/pages/abrindo-a-cozinha/privacidade-cliente-versao4.js" /* webpackChunkName: "component---src-pages-abrindo-a-cozinha-privacidade-cliente-versao-4-js" */),
  "component---src-pages-abrindo-a-cozinha-privacidade-entregador-versao-1-js": () => import("./../../../src/pages/abrindo-a-cozinha/privacidade-entregador-versao1.js" /* webpackChunkName: "component---src-pages-abrindo-a-cozinha-privacidade-entregador-versao-1-js" */),
  "component---src-pages-abrindo-a-cozinha-privacidade-entregador-versao-2-js": () => import("./../../../src/pages/abrindo-a-cozinha/privacidade-entregador-versao2.js" /* webpackChunkName: "component---src-pages-abrindo-a-cozinha-privacidade-entregador-versao-2-js" */),
  "component---src-pages-abrindo-a-cozinha-privacidade-entregador-versao-3-js": () => import("./../../../src/pages/abrindo-a-cozinha/privacidade-entregador-versao3.js" /* webpackChunkName: "component---src-pages-abrindo-a-cozinha-privacidade-entregador-versao-3-js" */),
  "component---src-pages-analytics-txt-js": () => import("./../../../src/pages/analytics.txt.js" /* webpackChunkName: "component---src-pages-analytics-txt-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacidade-clientes-js": () => import("./../../../src/pages/privacidade-clientes.js" /* webpackChunkName: "component---src-pages-privacidade-clientes-js" */),
  "component---src-pages-privacidade-entregadores-js": () => import("./../../../src/pages/privacidade-entregadores.js" /* webpackChunkName: "component---src-pages-privacidade-entregadores-js" */)
}

