import collect from 'collect.js'
import { isServerSide } from '../utils'

import { idsToPointers, removeBrokenPointers } from './pointers'

const Parse = isServerSide() ? require('parse/node') : require('parse')

if (!isServerSide()) {
  window.Parse = Parse
}

/**
 * Sorts rows based on arbitrary criteria
 * @description Sorts rows and returns them as an array
 */
const sortRows = (rows) =>
  // Initializes Collect.js list
  collect(rows)
    // Sort rows by
    .sortBy(
      (row) =>
        // Sort rows by order, href, name or id (in this order)
        row.order || row.href || row.name || row.objectId,
    )
    // Returns complete array without filtering it
    .all()

/**
 * Parses database rows into JSON objects
 * @description Converts row into JSON object and sanitizes its fields
 */
const parseRows = async (rows) => {
  // Creates parsed results array
  const parsed = []

  // For every row on the array, do
  // eslint-disable-next-line
  for (let index = 0; index < rows.length; index += 1) {
    // --- Retrieves row from the array
    const row = rows[index]

    // --- Converts row foreign keys to Parse Pointers
    // eslint-disable-next-line
    const attributes = await removeBrokenPointers(row.toJSON())

    // Adds it to parsed array
    parsed.push(attributes)
  }

  return parsed
}

/**
 * Queries Parse Platform for rows.
 * @description In case where clauses are provided, queries by them
 * Otherwise, returns the whole table rows
 */
const read = async (className, params = {}) => {
  // Retrieves Parse model based on className provided
  const Model = Parse.Object.extend(className)
  try {
    // Creates Parse Query object
    const query = new Parse.Query(Model).includeAll()
    // In case where clauses are provided
    if (params.where) {
      // For every clause, do
      Object.keys(params.where).forEach((key) => {
        // --- Gets where clause value
        const value = params.where[key]
        // --- Adds where clause to query
        query.equalTo(key, value)
      })
    }

    // Retrieves query results
    const rows = await query.findAll()

    // Parses rows into JSON objects
    const parsed = await parseRows(rows)

    // Orders Rows
    const sorted = sortRows(parsed)

    // Turns results into plain JS objects instead of Parse Objects
    return sorted
  } catch (e) {
    // eslint-disable-next-line
    console.log(e)
    // In case no result is found
    // Returns an empty array
    return []
  }
}

/**
 * Merges rows taking into account which row has changed lastly.
 * @description Checks for updatedAt timestamps in order to merge rows properly.
 */
const mergeRows = (databaseRows, localRows) => {
  // Maps updates based on:
  return localRows.map((localRow) => {
    // --- Gets local row data
    const { objectId, updatedAt: localUpdatedAt } = localRow

    // --- Retrieves matching row on the current database rows
    const databaseRow = databaseRows.find((x) => x.objectId === objectId) || {}

    // --- Gets database row data
    const { updatedAt: databaseUpdatedAt = '' } = databaseRow

    // --- It has been updated in case it does not, or if it hasn't been updated recently
    const localIsNewser = (localUpdatedAt || '') >= (databaseUpdatedAt || '')

    // --- Returns updates status
    return localIsNewser
      ? // ---- Merges using local changes as preferred content
        { ...databaseRow, ...localRow }
      : // ---- Merges using database changes as preferred content
        { ...localRow, ...databaseRow }
  })
}

/**
 * Finds deleted rows based on original rows x updated rows comparison.
 * @description In case the row exists on the updated list, ignores it
 * Otherwise, adds it to the deleted rows list
 */
const getDeletedRows = (databaseRows, localRows) => {
  // Filters database rows checking for:
  return databaseRows.filter((row) => {
    // --- Checks if the row existes on the updates
    const isExistingRow = localRows.filter((x) => x.objectId === row.objectId)[0]
    // --- Returns false if it doesn't exists (a.k.a.: The item has been deleted)
    return !isExistingRow
  })
}

/**
 * Deletes provided rows on the database.
 * @description For every row, do
 * Converts it into a Parse objects
 * Sets the specific row id
 * Deletes it from the database
 */
const deleteRows = async (className, rows) => {
  // Retrieves Parse model based on className provided
  const Model = Parse.Object.extend(className)

  // For every deleted row, do
  // eslint-disable-next-line
  for (let index = 0; index < rows.length; index += 1) {
    // --- Retrieves row from the array
    const row = rows[index]

    // --- Creates empty Parse Object instance
    const instance = new Model()

    // --- Sets id to row
    instance.id = row.objectId

    // --- Deletes row on database
    // eslint-disable-next-line
    await instance.destroy()
  }
}

/**
 * Formats row into Parse Object.
 * @description Converts objects into Parse format
 * Converts another tables references into foreign keys (Pointers)
 */
const formatRow = async (className, row) => {
  // Retrieves Parse model based on className provided
  const Model = Parse.Object.extend(className)

  // --- Converts row foreign keys to Parse Pointers
  // eslint-disable-next-line
  const attributes = await idsToPointers(row)

  // --- Creates Parse Object instance with row data
  const instance = new Model({
    ...attributes,
  })

  // --- Sets back the pointers into the Parse Object
  // eslint-disable-next-line
  for (const attributeKey in attributes) {
    instance.set(attributeKey, attributes[attributeKey])
  }

  // In case an id is provided
  if (row.id || row.objectId) {
    // Assigns it to the instance
    instance.id = row.id || row.objectId
  }

  // Set targetClass column as className
  instance.set('targetClass', className)

  return instance
}

/**
 * Formats rows into Parse Objects.
 * @description For every row, format it
 */
const formatRows = async (className, rows) => {
  const formattedRows = []

  // For every updated item, do
  for (let index = 0; index < rows.length; index += 1) {
    const row = rows[index]
    // --- Sets row order as array index for sorting purposes
    row.order = index + 1

    // --- Converts row into Parse Object
    // eslint-disable-next-line
    formattedRows[index] = await formatRow(className, row)
  }

  return formattedRows
}

/**
 * Updates Parse Platform table rows.
 * @description In case where clause is provided, updates matching row
 * Otherwise, updates the whole table rows contained in "updates" parameter
 */
const update = async (className, updates, params = {}) => {
  // In case where clauses are provided
  if (params.where) {
    // --- Retrieves matching row
    const row = (await read(className, params))[0]

    // eslint-disable-next-line
    if (row && row.id) updates.objectId = row.id

    // ---  Formats row into Parse Object
    const instance = await formatRow(className, {
      ...row,
      ...updates,
    })

    // --- Saves instance back to the database
    await instance.save()

    // --- Returns the row as JSON
    return [instance.toJSON()]
  }

  // Otherwise, considers the updates to be an array
  const localRows = [...updates]
  // eslint-disable-next-line
  console.log('These are the updates returned from the CMS:', localRows)

  // --- Gets current table rows
  const databaseRows = await read(className)
  // eslint-disable-next-line
  console.log('These are the current database rows:', databaseRows)

  // --- Merges database rows with the local updates
  const mergedRows = mergeRows(databaseRows, localRows)
  // eslint-disable-next-line
  console.log('These are the merged rows:', mergedRows)

  // ---  Formats merged rows into Parse Objects
  const formattedRows = await formatRows(className, mergedRows)
  // eslint-disable-next-line
  console.log('These are the formatted rows to be saved:', formattedRows)

  // --- Saves rows back to the Parse Platform
  await Parse.Object.saveAll(formattedRows)

  // Detects locally deleted table rows
  const deletedRows = getDeletedRows(databaseRows, localRows)
  // eslint-disable-next-line
  console.log('These are the rows deleted from the CMS:', deletedRows)

  // --- Deletes table rows from database
  await deleteRows(className, deletedRows)

  // --- Gets merged/updated/synched table rows
  const updatedRows = await read(className)
  // eslint-disable-next-line
  console.log('These are the updated database rows:', updatedRows)

  // --- Returns updates
  return updatedRows
}

export default {
  read,
  update,
}
