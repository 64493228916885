import React from 'react'
import PropTypes from 'prop-types'

import { MdSettings } from 'react-icons/md'

import { defaultOptions } from './helpers'

import './styles.scss'

const CmsSpeedDial = ({ options }) => (
  <div className="cms-speed-dial">
    {/* Main menu icon */}
    <div className="cms-speed-dial__icon">
      <MdSettings title="Ações rápidas" />
    </div>
    {/* Menu subentries */}
    <div className="cms-speed-dial__submenus-list">
      {/* Menu entry */}
      {options.map((option) => (
        <button
          type="button"
          key={option.uid}
          className="cms-speed-dial__submenu"
          onClick={option.onClick}
        >
          {option.icon || option.label}
        </button>
      ))}
    </div>
  </div>
)

CmsSpeedDial.propTypes = {
  options: PropTypes.arrayOf(Object),
}

CmsSpeedDial.defaultProps = {
  options: defaultOptions,
}

export default CmsSpeedDial
