export const updateFormRow = (form, updates) => {
  // Allows batch updates by avoiding form re-renders while the function is running
  form.batch(() => {
    // For every attribute update, do
    // eslint-disable-next-line
    for (const key in updates) {
      // --- Sets updated data on form
      form.change(key, updates[key])
    }
  }) // Now a single re-render will run
}

export const updateFormRows = (form, updates) => {
  // Updates rows data onto the form
  // eslint-disable-next-line
  form.getState().values.items = updates
  // Allows batch updates by avoiding form re-renders while the function is running
  form.batch(() => {
    // --- For every row update, do
    // eslint-disable-next-line
    for (let i = 0; i < updates.length; i++) {
      const row = updates[i]
      // ---- For every row attribute, do
      // eslint-disable-next-line
      for (const key in row) {
        // ----- Sets updated data on form
        form.change(`items[${i}].${key}`, row[key])
      }
    }
  }) // Now a single re-render will run
}
