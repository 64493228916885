import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import clsx from 'clsx'
// import { useDebounce } from 'react-use'

import { isServerSide } from '../../../utils'
import { FieldDescription, FieldLabel } from '../styles'

import { setImageUploadHandler, setSaveSettings } from './helpers'

import './styles.scss'

let config = {}
let JoditEditor = <></>

const buttons = [
  'paragraph',
  'fontsize',
  'brush',
  '|',
  'bold',
  'italic',
  'link',
  'underline',
  'strikethrough',
  'align',
  'indent',
  'outdent',
  '|',
  'ul',
  'ol',
  'source',
  'image',
  'video',
  'eraser',
  'copyformat',
  'undo',
  'redo',
  'save',
]

if (!isServerSide()) {
  const { Jodit } = require('jodit')
  JoditEditor = require('jodit-react').default
  config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    enableDragAndDropFileToEditor: false,
    beautyHTML: true,
    showCharsCounter: false,
    showXPathInStatusbar: false,
    useSearch: false,
    language: 'pt-BR',
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    buttonsSM: buttons,
    buttonsMD: buttons,
    buttons,
    controls: {
      // eslint-disable-next-line
      save: {},
      image: {
        ...Jodit.defaultOptions.controls.image,
        tooltip: 'Inserir imagem',
      },
      source: {
        ...Jodit.defaultOptions.controls.video,
        tooltip: 'Embedar código',
      },
      paragraph: {
        list: {
          // your key-value pairs
          // defaults are:
          p: 'Normal',
          aside: 'Box',
          blockquote: 'Quote',
          h1: 'Heading 1',
          h2: 'Heading 2',
          h3: 'Heading 3',
          h4: 'Heading 4',
        },
      },
    },
  }
}

const CmsHtmlField = ({ className, input, field, onSubmit }) => {
  const cssClasses = clsx('cms-html-field', className)
  const { value = '<p></p>' } = input
  const editor = useRef(null)
  setSaveSettings(config, onSubmit)
  setImageUploadHandler(config, (data) => {
    input.onChange(data)
  })

  return (
    <div className={cssClasses}>
      {field.label && <FieldLabel>{field.label}</FieldLabel>}
      {field.description && <FieldDescription>{field.description}</FieldDescription>}
      {!isServerSide() ? (
        <JoditEditor
          // eslint-disable-next-line
          tabIndex={1} // tabIndex of textarea
          ref={editor}
          value={value}
          config={config}
          onChange={(data) => {
            // setLocalValue(data)
            input.onChange(data)
          }}
          onBlur={(event) => {
            if (event?.target?.innerHTML) input.onChange(event?.target?.innerHTML)
          }} // preferred to use only this option to update the content for performance reasons
        />
      ) : (
        <></>
      )}
    </div>
  )
}

CmsHtmlField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape().isRequired,
  field: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func,
}

CmsHtmlField.defaultProps = {
  className: '',
  onSubmit: undefined,
}

export default CmsHtmlField
