import React from 'react'

import { useCmsConfig } from 'plugins/content/hooks'

const CmsConfig = () => {
  useCmsConfig()

  return <></>
}

export default CmsConfig
