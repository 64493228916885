import { useEffect } from 'react'
import { isServerSide } from '../utils'

const useLinkTag = (href) => {
  useEffect(() => {
    let link
    if (!isServerSide()) {
      link = document.createElement('link')

      link.href = href
      link.async = true

      document.body.appendChild(link)
    }
    return () => {
      if (!isServerSide()) document.body.removeChild(link)
    }
  }, [href])
}

export default useLinkTag
