import PropTypes from 'prop-types'
import React, { useState } from 'react'

import storage from 'plugins/content/services/storage'

import useCmsImage from '../../../hooks/useCmsImage'

import { showErrorDialog } from '../../../services/dialog'
import { FieldLabel, FieldDescription } from '../styles'

import { GENERIC_UPLOAD_ERROR, SIZE_IMAGE_ERROR } from '../../../config/error.consts'

import FileUpload from './FileUpload'
import { FileUploadField } from './styles'

const CmsFileField = ({ input, field }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [image] = useCmsImage(input.value, 'fixed')
  const onDrop = async ([file]) => {
    try {
      setIsLoading(true)
      const url = await storage.upload({
        file,
        folder: 'cms',
        filename: file.name,
      })

      input.onChange(url)
    } catch (e) {
      if (!file) {
        showErrorDialog(SIZE_IMAGE_ERROR)
      } else {
        showErrorDialog(GENERIC_UPLOAD_ERROR)
      }
    } finally {
      const timeoutRef = setTimeout(() => {
        setIsLoading(false)
        clearTimeout(timeoutRef)
      }, 2000)
    }
  }

  const onClear = () => input.onChange('')
  return (
    <FileUploadField>
      {field.label && <FieldLabel>{field.label}</FieldLabel>}
      {field.description && <FieldDescription>{field.description}</FieldDescription>}
      {image.fixed &&
        `Tamanho atual (largura x altura): ${image.fixed.width} x ${image.fixed.height} pixels`}
      <FieldLabel>Tamanho máximo: 2MB.</FieldLabel>
      <FileUpload
        value={input.value}
        accept={field.accept}
        onDrop={onDrop}
        onClear={onClear}
        isLoading={isLoading}
      />
    </FileUploadField>
  )
}

CmsFileField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  field: PropTypes.shape({
    label: PropTypes.string,
    accept: PropTypes.string,
    description: PropTypes.string,
    uploadDir: PropTypes.func,
    clearable: PropTypes.bool,
  }).isRequired,
  form: PropTypes.shape({
    getState: PropTypes.func,
  }).isRequired,
}

export default CmsFileField
