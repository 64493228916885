import PropTypes from 'prop-types'
import React from 'react'

import CmsContentAnchorField from '../CmsContentAnchorField'

const CmsThirdPageAnchorField = ({ field, form }) => (
  <CmsContentAnchorField field={field} form={form} />
)

CmsThirdPageAnchorField.propTypes = {
  form: PropTypes.shape().isRequired,
  field: PropTypes.shape().isRequired,
}

export default CmsThirdPageAnchorField
