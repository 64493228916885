import { useEffect } from 'react'
import { useCMS, usePlugin } from '@tinacms/react-core'
import { HtmlFieldPlugin } from 'react-tinacms-editor'
import { globalHistory } from '@reach/router'

import { isLoggedIn } from '../services/auth'

import useCmsFields from './useCmsFields'
import useLinkTag from './useLinkTag'

/**
 * useCmsConfig hook.
 * @version 1.0
 * @description Exposes TinaCMS as a hook for logged in users.
 */

const enableCmsSidebar = (cms) => {
  const timeoutRef = setTimeout(() => {
    // eslint-disable-next-line
    cms.sidebar.isOpen = true
    cms.enable()
    clearTimeout(timeoutRef)
  }, 500)
}

const forceRefresh = (cms = {}) => {
  const timeoutRef = setTimeout(() => {
    if (isLoggedIn()) {
      cms.disable()
      cms.enable()
    }
    clearTimeout(timeoutRef)
  }, 250)
}

const fontsHref =
  'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700&display=swap'

const useCmsConfig = () => {
  const cms = useCMS() // Retrieves CMS instance

  useLinkTag(fontsHref)
  useCmsFields() // Enables custom cms fields
  usePlugin(HtmlFieldPlugin) // Enables HTML WYSIYG field

  if (isLoggedIn() && cms.disabled) {
    // Enables CMS in case the user is logged in
    enableCmsSidebar(cms)
  }

  if (!isLoggedIn() && cms.enabled) {
    // Disables CMS in case no user session is found
    cms.disable()
  }

  useEffect(
    () =>
      globalHistory.listen(({ action }) => {
        if (action === 'PUSH') forceRefresh(cms)
      }),
    // eslint-disable-next-line
    [],
  )

  return [cms] // Returns CMS instance on hook return
}

export default useCmsConfig
