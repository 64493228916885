import { transformValues, parseObject } from '../utils'

/**
 * Converts Parse object to pointers (foreign key).
 * @description Needed in order to convert Parse ids to foreign keys.
 */
export const toPointer = (value) => ({
  __type: 'Pointer',
  className: value.className || value.targetClass,
  objectId: value.objectId,
})

/**
 * Checks if object is a reference to another table on Parse
 * @description Needed in order to convert Parse ids to foreign keys.
 */
export const isPointer = (value = {}) =>
  value !== null &&
  typeof value.objectId !== 'undefined' &&
  (typeof value.className !== 'undefined' || typeof value.targetClass !== 'undefined')

/**
 * Converts Parse objects to pointers (foreign keys).
 * @description Needed in order to convert Parse ids to foreign keys.
 */
export const idsToPointers = async (attributes) => {
  const result = await transformValues(
    // For every property on the attributes
    attributes,
    // --- Processes its value
    async (_, value) => {
      // --- Parses if the object it is stringified
      const parsed = parseObject(value)
      // --- In case it is a foreign key, converts it
      if (isPointer(parsed)) return toPointer(parsed)
      // --- Otherwise, returns the original object
      return parsed
    },
  )

  // Then returns the
  return result
}

/**
 * Removes broken pointers (foreign keys whose objects no longer exist).
 * @description Needed in order to prevent Parse null pointers on rows data.
 */
export const removeBrokenPointers = async (attributes) => {
  const result = await transformValues(
    // For every property on the attributes
    attributes,
    // --- Processes its value
    async (_, value) => {
      // --- Parses if the object it is stringified
      const parsed = parseObject(value)
      // --- In case it is a foreign key
      if (isPointer(parsed)) {
        // ------ If it has the proper data, returns it
        if (parsed.createdAt && parsed.updatedAt) return parsed
        // ------ Otherwise, returns empty data
        return undefined
      }
      // --- Otherwise, returns the original object
      return parsed
    },
  )

  // Then returns the
  return result
}
