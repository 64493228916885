import { useCMS } from '@tinacms/react-core'

import CmsFileField from '../components/Fields/CmsFileField'
import CmsHtmlField from '../components/Fields/CmsHtmlField'
import CmsBlogPostAnchorField from '../components/Fields/CmsBlogPostAnchorField'
import CmsThirdPageAnchorField from '../components/Fields/CmsThirdPageAnchorField'

export default function useCmsFields() {
  const cms = useCMS()

  cms.fields.add({
    name: 'file',
    Component: CmsFileField,
  })

  cms.fields.add({
    name: 'html',
    Component: CmsHtmlField,
  })

  cms.fields.add({
    name: 'blog-post-anchor',
    Component: CmsBlogPostAnchorField,
  })

  cms.fields.add({
    name: 'third-page-anchor',
    Component: CmsThirdPageAnchorField,
  })

  return cms
}
