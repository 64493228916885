import { Modal } from 'antd'

export const showSuccessDialog = ({ title, content }) =>
  Modal.success({
    title,
    content,
  })

export const showErrorDialog = ({ title, content }) =>
  Modal.error({
    title,
    content,
  })

export const showWarningDialog = ({ title, content }) =>
  Modal.warning({
    title,
    content,
  })
