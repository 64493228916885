export const canShowToast = ({ cms }) => cms?.alerts?.all?.length < 1

export const showSuccessToast = ({ cms }) =>
  canShowToast({ cms }) &&
  cms.alerts.success(
    'Alterações salvas com sucesso! Aguarde alguns minutos para vê-las replicadas no site ;)',
  )

export const showErrorToast = ({ cms }) =>
  canShowToast({ cms }) && cms.alerts.error('Falha na operação!')
