import React from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { TrashIcon } from '@tinacms/icons'
import Loader from 'components/Loader'
import { MAXIMUM_IMAGE_SIZE } from './helpers'

import {
  DropArea,
  FilePlaceholder,
  FileUploadPreview,
  DeleteButton,
  FilePreviewContainer,
} from './styles'

const AttachmentIcon = () => (
  <svg viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z"
    />
  </svg>
)

const FileUpload = ({ onDrop, onClear, value, accept, isLoading }) => {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept,
    onDrop,
    maxSize: MAXIMUM_IMAGE_SIZE,
  })

  const renderFilePlaceholder = () => (
    <FilePlaceholder>
      Arraste arquivos aqui para fazer o upload, ou clique para selecionar arquivos.
    </FilePlaceholder>
  )

  const renderFilePreview = () => (
    <FilePreviewContainer>
      <FileUploadPreview value={value}>
        <AttachmentIcon />
      </FileUploadPreview>
      {onClear && (
        <DeleteButton
          onClick={(e) => {
            e.stopPropagation()
            onClear()
          }}
        >
          <TrashIcon />
        </DeleteButton>
      )}
    </FilePreviewContainer>
  )

  const renderLoader = () => (
    <FilePlaceholder>
      <Loader />
      Carregando...
    </FilePlaceholder>
  )

  const renderContent = () => {
    if (isLoading) return renderLoader()
    if (value) return renderFilePreview()
    return renderFilePlaceholder()
  }

  return (
    <DropArea {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      {renderContent()}
    </DropArea>
  )
}

FileUpload.defaultProps = {
  accept: undefined,
  isLoading: false,
}

FileUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  accept: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default FileUpload
