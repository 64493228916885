import { useForm, usePlugin, useCMS } from '@tinacms/react-core'

import database from '../services/database'
import { isLoggedIn } from '../services/auth'
import { showSuccessToast, showErrorToast } from '../services/toast'

import { isEmpty, mergeContent } from '../utils'

import { updateFormRow, updateFormRows } from '../services/form'

import { triggerCmsBuild } from '../services/environment'

const useCmsContent = ({
  id = '',
  type = '',
  title = '',
  fields,
  getFields,
  asList = false,
  defaults = asList ? [] : {},
  query = {},
}) => {
  const cms = useCMS()
  const defaultContent = asList ? { items: defaults } : defaults

  // For unauthenticated users, we don't call the database functions
  let formConfig = {
    // Form settings
    id, // --- Form id
    onSubmit: () => {}, // --- Empty form submit due to type checking
    fields: getFields ? getFields() : fields, // --- Add form fields
    label: title, // --- Add form title
  }

  if (isLoggedIn()) {
    // For athenticated users, we do
    formConfig = {
      ...formConfig, // Extending base form config...
      async loadInitialValues() {
        // Load initial form values from database
        const updatedRows = await database.read(type, query)
        // Parses database row data
        const updatedRow = updatedRows[0] || {}
        //  Formats it into the expected form values format
        const initialValues = asList ? { items: updatedRows } : mergeContent(defaults, updatedRow)
        // Returns the initial values
        return initialValues
      },
      async onSubmit(values, form) {
        try {
          // In case the form values as handled as list
          if (asList) {
            // --- Updates rows as a list of rows
            const updatedRows = await database.update(type, values.items)
            // --- Sets back the updates onto the form
            updateFormRows(form, updatedRows)
          } else {
            // --- Declaes the query in order to match the row
            const updateQuery = { where: { objectId: values.objectId } }

            // --- Updates row as single row
            const updatedRows = await database.update(type, values, updateQuery)
            // --- Gets row data
            const updatedRow = updatedRows[0]
            // --- Sets back the updates onto the form
            updateFormRow(form, updatedRow)
          }
          // Triggers CMS build with updated content
          await triggerCmsBuild()
          showSuccessToast({ cms })
        } catch (e) {
          // eslint-disable-next-line
          console.log(e)
          // In case of any errors
          // --- Shows visual feedback
          showErrorToast({ cms })
        }
      },
    }
  }

  // Gets Tina handler for form
  // eslint-disable-next-line
  let [content, form] = useForm(formConfig)

  // Registers form fields
  if (form) form.fields = formConfig.fields

  // Registers form on tina sidebar
  usePlugin(form)

  // In case the user ist not logged in
  if (!isLoggedIn() || isEmpty(content)) {
    // --- Sets the content to default values
    content = defaultContent
  }

  // Returns content + form to hook caller
  return [content, form]
}

export default useCmsContent
