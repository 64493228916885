import storage from '../../../services/storage'

const appendParagraph = (editor) => {
  const paragraph = editor.selection.j.createInside.element('p')
  editor.selection.insertNode(paragraph)
  return paragraph
}
// eslint-disable-next-line
export const uploadImage = (editor, done) => {
  const imgInput = document.createElement('input')
  imgInput.setAttribute('type', 'file')
  imgInput.setAttribute('accept', 'image/*')
  imgInput.click()
  imgInput.onchange = async () => {
    if (!imgInput.files) return
    const imageFile = imgInput.files[0]
    const placeholder = appendParagraph(editor)
    placeholder.innerHTML = 'Carregando imagem...'
    const url = await storage.upload({
      file: imageFile,
      folder: 'cms',
    })
    placeholder.remove()
    const imageParagraph = appendParagraph(editor)
    imageParagraph.innerHTML = `<img src="${url}" alt=""/>`
    done()
  }
}
export const setImageUploadHandler = (config, onChange) => {
  // eslint-disable-next-line
  config.controls.image = Object.assign(Object.assign({}, config.controls.image), {
    exec: async (editor) => {
      await uploadImage(editor, () => {
        onChange(editor.value)
      })
    },
  })
}
export const setSaveSettings = (config, onSubmit) => {
  if (onSubmit) {
    // eslint-disable-next-line
    config.controls.save = {
      tooltip: 'Salvar conteúdo',
      exec: () => {
        return onSubmit()
      },
    }
  } else {
    // eslint-disable-next-line
    delete config.controls.save
  }
}
