import styled, { css } from 'styled-components'

export const FieldLabel = styled.label`
  display: block;
  font-size: var(--tina-font-size-1);
  font-weight: 600;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--tina-color-grey-7);
  transition: all 85ms ease-out;
  text-align: left;
  ${(props) =>
    props.error &&
    css`
      color: var(--tina-color-error) !important;
    `};
`

export const FieldDescription = styled.p`
  color: var(--tina-color-grey-6);
  font-size: var(--tina-font-size-0);
  font-style: italic;
  font-weight: lighter;
  padding-top: 4px;
  white-space: normal;
  margin: 0;
`
